const iconBase = App.mediaPath + "icons/";

// Initialize and add the map
function initContactMap() {
  const el = document.getElementById("contactMap");
  const contactMapCenterPosition = {
    lat: 45.30603314584789,
    lng: 13.592193036089737,
  };
  const location1 = { lat: 45.30603314584789, lng: 13.592193036089737 };
  const location2 = { lat: 45.17651832851798, lng: 13.593271595249734 };

  const cityPageMap = new google.maps.Map(el, {
    zoom: 13,
    center: contactMapCenterPosition,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  });

  new google.maps.Marker({
    position: location1,
    map: cityPageMap,
    icon: iconBase + "map-marker.png",
    animation: google.maps.Animation.DROP,
    title: "Tuna Bay Lanterna Camping",
  });

  new google.maps.Marker({
    position: location2,
    map: cityPageMap,
    icon: iconBase + "map-marker.png",
    animation: google.maps.Animation.DROP,
    title: "Tuna Bay Istra Camping",
  });

  const bounds = new google.maps.LatLngBounds();
  bounds.extend(location1);
  bounds.extend(location2);
  cityPageMap.fitBounds(bounds);
}

$(function () {
  if ($("#contactMap").length) {
    initContactMap();
  }
});
