(function ($) {

  "use strict";

  function Common () {
    let bindSmoothScrollToElement = function () {
      // handle links with @href started with '#' only
      $(document).on('click', 'a[href^="#"]:not([data-toggle="tab"])', function(e) {
        // target element id
        var id = $(this).attr('href');

        // target element
        var $id = $(id);
        if ($id.length === 0) {
          return;
        }

        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault();

        // top position relative to the document
        var pos = $id.offset().top;

        // animated top scrolling
        $('body, html').animate({scrollTop: pos});
      });
    };

    let bindHambuger = function () {
      let $hamb = $('.js-hamburger');
      let $nav = $('.js-nav');

      $hamb.on('click', function () {
        $hamb.find('> div').toggleClass('active');
        $('body').toggleClass('mobile-nav-active main-menu-active');
        $nav.toggleClass('active');
      });
    };

    let init = function (parentElement) {
      bindHambuger();
      bindSmoothScrollToElement();
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.Common = new Common();

})(jQuery);
